import React from 'react';
import styled from 'styled-components';
import '../../content/assets/icons/fontello-881eb42b/css/fontello.css'

const SocialLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all .3s;
  background: var(--CAPPUCCINO);
  color: var(--CREAM_TINT);
  border: 1px solid var(--CAPPUCCINO);
  margin-right: ${ props => props.mr || '0' };
  margin-left: ${ props => props.ml || '0' };

  &:hover {
    color: var(--CAPPUCCINO);
    background: var(--CREAM);

  }
`;

const SocialIcon = (props) => {
  return (
    <SocialLink
      target="_blank"
      href={ props.href }
      mr={ props.mr }
      ml={ props.ml }
    >
      <i
        className={ props.icon }
      />
    </SocialLink>
  );
};

export default SocialIcon;