/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import Image from "gatsby-image"
import SocialIcon from './social-icon'


// import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/logo--red.png/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
            facebook
          }
        }
      }
    }
  `)

  const { /* author, */ social } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `inline-flex`,
        margin: '0 auto'
        // marginBottom: rhythm(2.5),
      }}
    >    

        <SocialIcon
          href={`https://facebook.com/${social.facebook}`}
          icon={ 'icon-fb' }
        />

        <SocialIcon
          href={`https://twitter.com/${social.twitter}`}
          icon={ 'icon-twitter' }
          ml={ '5px' }
        />
    </div>
  )
}

export default Bio
