import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import styled from "styled-components"
import breakpoints from "./../styles/breakpoints"
import { fluidRange, darken } from "polished"

const Post = styled.article`
  background: var(--CREAM_TINT);
  box-shadow: 0 0 15px 1px var(--CAPPUCCINO_TINT);
  cursor: pointer;
  transition: all 0.4s;
  padding: ${rhythm(1.2)};
  margin-bottom: ${rhythm(2)};
  border-radius: 2rem;

  @media (min-width: ${breakpoints.phoneUp}) {
    padding: ${rhythm(2)};
  }

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 0 26px 3px var(--CAPPUCCINO);
  }
`

const PostTitle = styled.h2`
  margin-top: 0;
  margin-bottom: ${rhythm(1 / 4)};
  color: var(--BLUE_TEXT);
  ${fluidRange({
    prop: "font-size",
    fromSize: "2rem",
    toSize: "2.5rem",
  })}
`

const ArticleInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ArticleAuthor = styled.em`
  font-size: 0.9em;
  color: var(--CAPPUCCINO_SHADE);
`

const ArticleDate = styled.small`
  color: var(--CAPPUCCINO_SHADE);
`

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    // const posts = data.allMarkdownRemark.edges
    const posts = data.allMdx.edges
    const images = data.allImageSharp.edges

    const findImage = _imageName => _imageQuery => {
      if (_imageName && _imageQuery) {
        return _imageQuery.node.original.src.includes(_imageName)
      }
      return ""
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="HOME" />
        <hr
          style={{
            margin: "0 0 1.2rem",
            width: "45%",
            background: "var(--CAPPUCCINO_TINT)",
          }}
        />
        <Bio />
        <hr
          style={{
            margin: "1.2rem 0 3rem",
            width: "45%",
            background: "var(--CAPPUCCINO_TINT)",
          }}
        />
        {posts.map(({ node }, i) => {
          const title = node.frontmatter.title || node.fields.slug
          const imageName = node.frontmatter.image
          const imageSrc = images.find(findImage(imageName))
            ? images.find(findImage(imageName)).node.original.src
            : null
          const imageAlt = node.frontmatter.imageAlt

          return (
            <Link
              key={node.fields.slug}
              style={{
                boxShadow: `none`,
                width: "100%",
              }}
              to={`/blog/${node.fields.slug}`}
            >
              <Post index={i}>
                <header
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <PostTitle> {title} </PostTitle>
                  <ArticleInfo>
                    <ArticleAuthor>By {node.frontmatter.author}</ArticleAuthor>
                    <ArticleDate> {node.frontmatter.date} </ArticleDate>
                  </ArticleInfo>
                </header>
                <section>
                  {imageSrc && (
                    <img
                      src={imageSrc}
                      alt={imageAlt}
                      style={{
                        borderRadius: rhythm(0.3),
                      }}
                    />
                  )}
                  <p
                    style={{
                      margin: 0,
                      color: darken("0.2", "#bb9358"),
                      fontSize: "1.1rem",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt,
                    }}
                  />
                </section>
              </Post>
            </Link>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY年 M月 D日")
            title
            description
            author
            image
            imageAlt
          }
        }
      }
    }
    allImageSharp {
      edges {
        node {
          original {
            src
          }
        }
      }
    }
  }
`
